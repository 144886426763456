<template>
  <NuxtPage />
</template>

<script setup>
  if (process.client) {
    import("bootstrap");
  }

  const { page } = useContent();
  watch(() => page.value.title, () => {
    useContentHead({
      head: { title: `ubity | ${page.value.title}` }
    });
  });
</script>