import revive_payload_client_4sVQNw7RlN from "/builds/ubity/privat/ubity.io/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/ubity/privat/ubity.io/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/ubity/privat/ubity.io/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/ubity/privat/ubity.io/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/ubity/privat/ubity.io/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/ubity/privat/ubity.io/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/ubity/privat/ubity.io/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/ubity/privat/ubity.io/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/ubity/privat/ubity.io/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import documentDriven_cMiI6j6ghD from "/builds/ubity/privat/ubity.io/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import vue3_apexcharts_plugin_client_V38fKWrfRF from "/builds/ubity/privat/ubity.io/plugins/vue3-apexcharts-plugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  documentDriven_cMiI6j6ghD,
  vue3_apexcharts_plugin_client_V38fKWrfRF
]